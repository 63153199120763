/* -------------------- General Site layouts -------------------- **/
*{
	font-family: $font-stack;
	color: #575772;
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
}

body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

img{
	max-width: 100%;
}


/*** Page ***/
.PageResults,
.InnerPage{
	.banner{
		height: 66vh;
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}


.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}

.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: $themeColor;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #dedede;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: $themeColor;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: #d92d2a;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid #cccccc;
	}

}

.MainMenuClose{
	display: none !important;
}

.mainContent{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.addToCalendar,
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.7em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    font-weight: lighter; 
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: $themeColor;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

/** Inputs  **/
.ContactFormContainer,
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	select,
	textarea{
	    padding: 10px 20px;
    	background-color: #fff;
    	border-radius: 4px;
    	border: 1px solid #efefef;
    	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}
	select{
		height: 41px;
		
	}

	label{
		display: none;
	}

	.dropdown,,
	.selectField{
		position: relative;
		select{

		    -webkit-appearance: none;
		    -moz-appearance: none;
		    appearance: none;
		}
		&:before{
		    position: absolute;
		    z-index: 999;
		    display: block;
		    content: "";
		    height: 10px;
		    width: 10px;
		    border-bottom: 2px solid $themeColor;
		    border-left: 2px solid $themeColor;
		    transform: rotate(-45deg);
		    bottom: 26px;
		    right: 20px;
		}
	}

	label{
		font-weight: lighter;
	}

	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
/** Inputs  **/



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	    background-color: $themeColor !important;

		 &:hover{
	    	    background-color: #c2a544 !important;
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		font-weight: normal !important;
		color: $themeColor !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: $themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #000;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #e2e0df !important;
		color: #1b3080 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}





.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 20%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid $themeColor !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{
	min-height: 400px;
}

.SearchContainer{
    display: none;
    position: fixed;
    top: -4px;
    background-color: #dbb95a;
    padding: 7px;
    right: 0px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #dbb95a;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #dbb95a;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 
/** General Site layouts **/
















.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(41,37,89,0.76);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#gmap_canvas{
	min-height: 500px;
}

.SearchContainer{
    display: none;
    position: fixed;
    top: -9px;
    background-color: #3d3a61;
    padding: 7px;
    left: 20px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn, 
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #56b4b9;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;


	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 5px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #56b4b9;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
	    float: left;
    	display: block;
    }
}

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #1c2023;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: #9c1816;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: #ca1916;
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #ca1916;
}

.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	min-height: 300px;
	padding: 161px 20px 50px 20px;
	@include background-cover(url("../images/img5.jpg"));

	.darken{
		@include stretchCover(rgba(87, 171, 130, 0.77), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/bg.jpg"));
}


#google_translate_element{
    border: 30px solid #312d5e;
}

.searchToggle{
	cursor: pointer;
	z-index: 8000;
	*{
		color: #fff;
	}
	position: fixed;
	top: 5px;
	left: 10px;
	span{
	display: inline-block;
	margin-left: 10px;
	}
}


/*** Page ***/
.Page{
	
}
/*** Footer ***/
footer{
	padding: 30px 0px 0px 0px;
	z-index: 8;
    background: #fff;
}


/*** Simple Menu CSS ***/
.LevelOneMenu{
	position: relative;
	display: block;
	text-align: right;
	font-size: 1.3em;
	box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	*{
		font-size: 1em;
		color: #575772;
		font-weight: lighter;
		box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}
	li,ul{
		margin: 0px;
		padding: 0px;
	}
}
.LevelOneMenuItem{
	display: inline-block;
	position: relative;
	vertical-align: middle;
	&:hover{
		background-color: #fff;
		.LevelTwoMenu{
			display: block;
		}
	}
}
.LevelOneMenuLink{
	display: block;
	position: relative;
	width: 100%;
	float: left;
	clear: both;
	padding: 10px;
}
.LevelTwoMenu{
	position: absolute;
	display: none;
	left: 50%;
	min-width: 250px;
	bottom: 0px;
	@include box-shadow(0px 4px 10px 0px rgba(0,0,0,0.3));
	background-color: $themeColor;
	@include transform(translateX(-50%) translateY(100%));
}
.LevelTwoMenuItem{  
	display: block;
	position: relative;
	width: 100%;
	float: left;
}
.LevelOneMenuLink{
	display: block;
	position: relative;
	width: 100%;
	text-align: left;
	float: left;
	padding: 10px 10px 30px 10px;
	@include transition($t1);
	background-color: rgba(0,0,0, 0);
	&:hover{
		color: $themeColor;
	}
}

.LevelTwoMenuLink{
	text-align: left;
	display: block;
	position: relative;
	width: 100%;
	float: left;
	color: #fff;
	clear: both;
	font-size: 0.8em;
	padding: 5px 10px;
}


.LevelOneMenu .LevelOneMenuItem:last-of-type .LevelOneMenuLink:after{
	display: none !important;
}

.WideMenu{
	position: static;
	.LevelOneMenuItem{
		position: static;
	}
	.LevelTwoMenu {
    	width: 100%;
    	padding: 20px 15%;
	}
	.LevelTwoMenuItem{
		width: 25%;
		display: block;
		float: left;
		clear: none;
	}
}
/*** Simple Menu CSS ***/

.searchBlackout{
	position: fixed;
	top: 0px; 
	left: 0px;
	width: 100%;
	display: none;
	height: 100%;
	z-index: 99999;
	background-color: rgba(0,0,0,0.6);
}
.SearchContainer{
    display: none;
    cursor: pointer;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 240px;
    @include transform($center);
    background-color: $themeColor;
    padding: 7px;
    z-index: 9999999;

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    } 
 
    button{
	    background-color: $themeColor;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: $themeColor;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 



.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
    @include transform($center);
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.quoteBox {
    position: absolute;
    top: 50%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
}


#header{
	position: fixed;
	z-index: 9999;
	padding-top: 40px;    
	min-height: 70px;
	padding-right: 10px;
	background-color: rgba(255,255,255,1);
}

.homebtn{
	position: absolute;
	top: -24px;
	left: 20px;
}
#Home2{
	padding: 0px 20px 40px 20px;
}

/* -------------------- General Site layouts -------------------- **/




/* -------------------- Site Build -------------------- **/
.logo{
	height: 70px;
}

.BannerText{
	z-index: 4;
	text-align: center;
	*{
		color: #fff;
		font-size: 1em;
	}
	.bannerPin{
		width: 50px;
		margin-bottom: 10px;
	}
	.BannerTextPartOne{
		font-size: 5em;
    	line-height: 1em;
   		margin-bottom: 20px;
		font-family: professor, sans-serif;
	}
	.BannerTextPartTwo{
		font-size: 1.3em;
	}
}


#Home1{
	text-align: center;
	padding: 100px 0px 0px 0px;
	*{
		font-size: 1em;
	}
	.SectionTitleOne{
		color: #74c09a;
		font-size: 8.7em;
		line-height: 1em;
		font-family: professor, sans-serif;
	}
	.SectionTitleTwo{
		text-transform: uppercase;
		font-size: 2em;
		margin-bottom: 38px;
	}
	.SectionText{
		font-weight: lighter;
		font-size: 1.3em;
	
	.RightSection{
		img{
			width: 80%;
		}
	}
}
}
	.TextGreen{
		font-weight: bold;
		color: #74c09a;
	}

	.bg1{
		position: absolute;
	    height: 120px;
	    left: 0px;
	    background-color: #fff;
	    top: 0px;
	    width: 100%;
	    background-repeat: repeat-x;
	    background-size: 100px auto;
	    background-position: bottom left;
		background-image: url(../images/bg1.jpg);
	}
	.SectionText{
		font-weight: lighter;
		font-size: 1.3em;
	}
#Home2{
	text-align: center;
	.bg1{
		position: absolute;
	    height: 120px;
	    left: 0px;
	    background-color: #fff;
	    top: 0px;
	    width: 100%;
	    background-repeat: repeat-x;
	    background-size: 100px auto;
	    background-position: bottom left;
		background-image: url(../images/bg1.jpg);
	}
		background-repeat: repeat;
		background-position: -0px 120px;
		background-size: 100px auto;
		background-image: url(../images/bg2.jpg);
		.col-3, .col-4{padding-bottom: 60px;    font-size: 0.9em;}
		.col-3:after, .col-4:after{
			content: '';
			position: absolute;
			display: block;
			bottom: 0px;
			height: 4px;
			width: 80%;
			left: 50%;
			bottom: 17px;
			background-color: $themeColor;
			@include transform(translateX(-50%));
		}
}

.IconContainer{
	height: 160px;
}
.iconCircle{
	position: absolute;
	top: 50%;
	left: 50%;
	height: 160px;
	width: 160px;
	@include border-radius(50%);
	@include transform($center);
	background-color: $themeColor;
	img{
		position: absolute;	
		top: 50%;
		left: 50%;
		    height: 40%;
		@include transform($center);
	}
}

.TopBlockTitle{
	text-transform: uppercase;
	font-size: 1.8em;
	padding: 20px 0px;
}
.TopBlockText{
	font-weight: lighter;
	font-size: 1.3em;
	line-height: 1em;
	*{
		font-size: 1em;
	}
}
input[type=submit]{
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
input[type=submit],
.ReadMore{
	padding: 8px 30px;
	cursor: pointer;
    border: 1px solid $themeColor;
    display: inline-block;
    text-transform: uppercase;
    font-weight: lighter;
    color: #575772;
    @include transition($t1);
    &:hover{
		background-color: $themeColor;
		color: #fff;
    }
}
#Boxes{
	text-align: center;
	.LinkBox{
		padding-bottom: 20%;
	}
	*{
		color: #fff;
	}
	.bg{
		position: absolute;
		background-position: center;
		background-size: cover;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		@include transition($t1);
		@include transform(scale(1));
		background-image: url(../images/img1.png);
	}
	.darken{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		@include transition($t1);
		background-color: rgba(59, 59, 60, 0.82);
	}
	.overlayTextOne{
		@include transition($t1);
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center);
		text-transform: uppercase;
		font-size: 1.7em;
		padding: 15px;
		width: 77%;
		opacity: 1;
		max-width: 300px;
		border-bottom: 4px solid $themeColor;
	}
	.overlayTextTwo{
		@include transition($t1);
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center);
		width: 77%;
		opacity: 0;
		max-width: 300px;
	}
	.LinkBox{
		overflow: hidden;
	}
	.LinkBox:hover{
		.overlayTextOne{
			opacity: 0;
		}
		.overlayTextTwo{
			opacity: 1;
		}
		.bg{
			@include transform(scale(1.1));
		}

		.darken{
			background-color: rgba(59, 59, 60, 0.92);
		}
	}
}

#Home3{
	.inner{
		width: 700px;
	}
	*{
		font-size: 1em;
		color: #fff;
	}
	padding: 80px 20px;
	background-position: center;
	background-image: url(../images/bg3.jpg);
	.Title{
		font-size: 4em;
		line-height: 1em;
		font-family: professor, sans-serif;
		margin-bottom: 15px;
	}
	.Home3Text{
		font-size: 1.3em;
		margin-bottom: 25px;
	}

	.ReadMore{
	    width: 200px;
	    border: 1px solid #fff;
	 	&:hover{
			background-color: #fff;
			color: $themeColor;
	    }
	}

}

.FooterPartOne{
	margin-bottom: 20px;
    font-size: 1.2em;
}
.FooterPartTwo{
	font-size: 0.8em;
	*{font-size: 1em;}
}

footer{
	text-align: center;
	padding: 30px 20px;
	*{
		text-align: center;
		font-size: 1em;
	}
	img{
		max-width: 100%;
		width: 300px;
	}
}

.WhatWeDoContainer{
	padding: 160px 20px 31vw 20px;
	text-align: center;
	border-bottom: 20px solid $themeColor;
	.inner{
		width: 600px;
	}
	.sectionImage{
		position: absolute;
		left: 50%;
		bottom: 0px;   
		width: 80%;
		@include transform(translateX(-50%) scale(0.8));
		transform-origin: bottom center;
		-ms-transform-origin: bottom center;
		-moz-transform-origin: bottom center;
		-webkit-transform-origin: bottom center;
		-o-transform-origin: bottom center;
	}
}
.AdvertisementPage{

	.WhatWeDoContainer{
		.sectionImage{
			width: 60%;
		}
	}
}
.pageTitle{
	font-family: professor, sans-serif;
	color: $themeColor;
	font-size: 5em;
	padding-bottom: 10px;
}

.InnerPage{
	footer{
		border-top: 4px solid rgb(238, 238, 238);
	}
	#header{
		border-bottom: 4px solid rgb(238, 238, 238);
	}
}

.TextSection{
	padding-bottom: 20px;
	font-size: 1.3em;
	*{
		font-size: 1em;
		font-weight: lighter;
	}
}


.WhatWeDoTextSections{
	overflow: hidden;
	padding: 100px 0px;
	*{
		font-size: 1em;
	}

	.pageTitle{
	font-size: 5em;
	text-align: center;
	}
	.col-6{
		font-size: 1.3em;
		font-weight: lighter;
		padding: 0px 20px;
	}
}
.pin2{
	position: absolute;
	left: -5%;
	bottom: 0px;
	height: 80%;
}

.OurWorkSectionTwo{

	padding: 80px 0px;
	background-position: top left;
	background-size: 108px auto;
	background-image: url(../images/bg3.jpg);
	*{
		color: #fff;
		font-size: 1em; 
	}
	.SectionTitle{
		text-transform: uppercase;
		font-size: 1.8em;
	}
	.SectionText{
		font-size: 1.3em;
	}
}

.FlipMapContainer{
	padding: 100px 20px;
	background-repeat: repeat;
	background-position: 0px 0px;
	background-size: 100px auto;
	background-image: url(../images/bg2.jpg);
}
.FlipMapOuter{
	padding-bottom: 52%;
}
.FlipMapInner{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	webkit-perspective: 1000px;
    perspective: 1000px;
    transform-style: preserve-3d;
	.flipmap{
    	transform-style: preserve-3d;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80%;
		height: 80%;
    	@include transition(transform 1.5s);
		@include transform($center rotateY(0deg));
	}
	.front{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-image: url(../images/front.png);
		backface-visibility: hidden;
    	-webkit-backface-visibility: hidden;
    	background-size: contain;
    	background-position: center;
    	background-repeat: no-repeat;
		@include transform(rotateY(0deg));
	}
	.back{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-image: url(../images/back.png);
		backface-visibility: hidden;
    	-webkit-backface-visibility: hidden;
    	background-size: contain;
    	background-position: center;
    	background-repeat: no-repeat;
		@include transform(rotateY(-180deg));
	}
}
.FlipMapInner.active{
	.flipmap{
		@include transform($center rotateY(-180deg));
	}
}
.MapNext,
.MapPrev{
	height: 40px;
	width: 40px;
	cursor: pointer;
	@include border-radius(50%);
	display: block;
    position: relative;
	background-color: $themeColor;
	opacity: 0.6;
	@include transition($t1);
	i{
		position: absolute;
		top: 50%;
		left: 50%;
		color: #fff;
		font-size: 1em;
		@include transform($center);
	}
	&:hover{
		opacity: 1;
	}
}
#FlipTwo{
	display: none;
}

.MapPrev{
	top: 50%;
	position: absolute;
	left: 20px;
	@include transform(translateY(-50%));
	i{
		margin-right: 2px;
	}
}
.MapNext{
	top: 50%;
	position: absolute;
	right: 20px;
	@include transform(translateY(-50%));
	i{
		margin-left: 2px;
	}
}

.activeToggle{
	color: #fff;
	background-color: $themeColor;
}

.GreySection{
	background-color: #525756;
	padding: 30px 5% 40px 5%;
	text-align: center;
	color: #fff;
	*{color: #fff;}
    font-size: 1.3em;
	font-style: italic;
}

.OurWork,
.AdvertisementPage {
	.WhatWeDoContainer{
		border-bottom: none !important;
	}
}


.MapToggle{
	margin-bottom: 10px;
}

.AdvertisementSectionBottom{
	z-index: 5;
    background-repeat: repeat;
    background-position: 54px -17px;
    background-size: 108.5px auto;
	background-image: url(../images/bg2.jpg);
	.bgOverlay{
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100px;
		width: 100%;
		background-position: top left;
	    background-position: 0px 40px;
		background-image: url(../images/bg3.jpg);
	}
	.inner{
		padding: 100px 0px 0px 0px;
		background-color: #fff;
		@include box-shadow(0px 0px 10px 0px rgba(0,0,0,0.24));
		.textContainer{
				padding-left: 50%;
				padding-right: 5%;
				padding-bottom: 30px;
		}
		img{
			position: absolute;
			left: 25%;
			bottom: 0px;
			height: calc(100% - 100px);
			@include transform(translateX(-50%));
		}
	}
}

.ContactFormContent{
	padding: 140px 20px 0px 20px;
}
.ContactFormContainer{
	padding: 170px 20px 100px 20px;
	background-repeat: repeat;
	background-position: -0px 120px;
	background-size: 100px auto;
	background-image: url(../images/bg2.jpg);
}
.pageSubTitle{
	font-size: 1.6em;
	color: $themeColor;
	font-weight: lighter;
	margin-bottom: 20px;
}
.ContactFormContent,
.ContactFormContainer{
	.inner{width: 800px;}
}

.LeftField{
	width: 50%;
    padding-right: 10px;
    float: left;
}
.RightField{
    width: 50%;
    padding-left: 10px;
    float: left;
}

.Actions{
    text-align: center;
    padding: 15px;
}

.Lazy{
	opacity: 0;
	@include transform(scale(0.8));
}
.ActiveIn .Lazy{
	opacity: 1;
	@include transform(scale(1));
}
.ActiveIn .Lazy.sectionImage{
	@include transform(scale(1) translateX(-50%));
}


.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

 z-index: 999999999;
  -webkit-transform: translate3d(0, -50px, 0);
  -ms-transform: translate3d(0, -50px, 0);
  transform: translate3d(0, -50px, 0);

  -webkit-transition: -webkit-transform .5s ease-out;
  -ms-transition: -webkit-transform .5s ease-out;
  transition: transform .5s ease-out;
}

.pace.pace-active {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}


.pace .pace-progress {
  display: block;
  position: fixed;
  z-index: 999999999;
  top: 0;
  right: 100%;
  width: 100%;
  height: 3px;
  background: $themeColor;
  pointer-events: none;
}

.loadingSection{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: #fff;
    z-index: 99999999;
    text-align: center;
}
.centerLoader{
	position: absolute;
	top: 50%;
	left: 50%;
	img{width: 30px;}
	@include transform($center);
} 
.loadingText{
	color: #efefef	;
    text-transform: uppercase;
    font-size: 0.5em;
    letter-spacing: 4px;
    font-weight: bold;
    padding: 10px;
}
.Loading{
	overflow: hidden !important;
	height: 100vh;
	width: 100vw;
	.banner{
		opacity: 0 !important;
	}
}


.checkbox{
	label{
		font-weight: lighter !important;
		display: block;
		padding-bottom: 20px;
	}
	.checkbox{
    	margin-right: 10px;
    	float: left;
	}
}
/* -------------------- Site Build -------------------- 

font-family: professor, sans-serif;

font-weight: 400;

font-style: normal;
**/


a {
    color: #74c09a;
}

.fancybox-lock .fancybox-overlay {
    z-index: 9999;
}

