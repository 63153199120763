
/* ------------  General Site Responsive ----------- */

@media all and (max-width: 1200px)  {
	#MenuIcon{
		display: block !important;
	}
	#Menu{
		display: none !important;
	}

	.LevelOneMenu{
		display: none;
	}
	.logo {
	    height: 43px;
	}
	.homebtn {
	    top: -32px;
	}
	.banner {
    	padding: 110px 20px 50px 20px;
	}
}

@media all and (max-width: 786px)  {
	.tacMid{
		text-align: center !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
}

@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
/*** Gallery Responsive ***/

/* ------------  General Site Responsive ----------- */
/* ------------  Site Responsive ----------- */

@media all and (max-width: 1400px)  {

	.AdvertisementSectionBottom .inner img {
		height: auto;
	    bottom: 0px;
	    width: 42%;
	}

}
@media all and (max-width: 1300px)  {
	#Home1 .SectionTitleOne {
	    color: #74c09a;
	    font-size: 4.7em;
	}
	#Home1 .SectionTitleTwo {
   		font-size: 1.4em;
	}
	#Boxes .LinkBox {
		padding-bottom: 30%;
	    font-size: 0.7em;
	}
}
@media all and (max-width: 900px)  {
	#Boxes .LinkBox {
		padding-bottom: 30%;
	    font-size: 0.6em;
	}
}
@media all and (max-width: 770px)  {
	.WhatWeDoTextSections .col-6,
	.OurWorkSectionTwo .col-6{
		text-align: center;
		width: 100%;
	}
}
@media all and (max-width: 720px)  {
	#Boxes .LinkBox {
		width: 100%;
		clear: both;
		padding-bottom: 0px;
		height: 200px;
	}
}
@media all and (max-width: 1200px)  {

	.AdvertisementSectionBottom .inner .textContainer {
	    padding: 20px;
	    text-align: center;
	    padding: 20px;
	    text-align: center;
	    padding-bottom: 42vw !important;
	}
	.AdvertisementSectionBottom .inner img{
		left: 50%;
	}

}
@media all and (max-width: 1150px)  {
	.TopBlockTitle {
	    font-size: 1.2em;
	}
	.GreySection {
   		font-size: 1.3em;
	}
}
@media all and (max-width: 1000px)  {
	.FooterPartOne {
	    font-size: 1em;
	}
	footer img {
	    width: 240px;
	    margin-bottom: 20px;
	}
	.pageTitle {
	    font-size: 4em;
	}
	.BannerText .BannerTextPartOne {
    	font-size: 3em;
	}
}

@media all and (max-width: 950px)  {
	#Home1{
		.col-6{
			width: 100%;
			clear: both;
		}
	}
	#Home2 .col-3,
	#Home2 .col-4{
		width: 100%;
		clear: both;
		padding-bottom: 100px;
	}
	#Home2 .col-3:after,
	#Home2 .col-4:after {
    	width: 50px;
	    bottom: 48px;
	}
}
@media all and (max-width: 580px)  {
	footer img {
	    width: 200px;
	    margin-bottom: 20px;
	}
	.RightField,
	.LeftField{
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
	}
	.SectionText {
    	font-size: 1em;
	}
	#Home3 .Title {
    	font-size: 2.7em;
	}


	.pageTitle {
	    font-size: 2.8em;
	}
	.TopBlockTitle {
	    font-size: 1.2em;
	}
}

@media all and (max-width: 450px)  {
	.logo {
	    height: 31px;
	}
	.homebtn {
	    top: -24px;
	}
}
/* ------------  Site Responsive ----------- */
